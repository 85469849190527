/* Reset and base styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: #333;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* App container */
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Global button styles */
button {
  cursor: pointer;
  font-family: inherit;
}

/* Global form element styles */
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 16px;
}

/* Utility classes */
.text-center {
  text-align: center;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

/* Responsive text */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }
}

/* Basic styling for the app */
:root {
  --color-black: #000;
  --color-black-light: #1b1919;
  --color-white: #fff;
  --color-gunmetal: #383945;
  --color-gunmetal-dark: #2d2d2f;
  --color-gold-one: #c7c5b3;
  --color-gold-two: #9a8866;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-gunmetal-dark);
  color: var(--color-gold-one);
}

.App {
  min-height: 100vh;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: var(--color-gold-one);
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid var(--color-gold-two);
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Fullscreen map mode */
body.fullscreen-map {
  overflow: hidden;
}

.fullscreen-map {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
