.score-card {
  background-color: var(--bg-darker);
  border-radius: 6px;
  border: 1px solid var(--border-dark);
  overflow: visible;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
  margin: 0 auto;
}

.score-header {
  background-color: var(--bg-medium);
  padding: 12px 16px;
  border-bottom: 1px solid var(--border-dark);
  text-align: center;
}

.score-header h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
}

.score-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.score-points {
  text-align: center;
  padding: 10px 0;
}

.score-number {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  display: block;
  color: var(--accent-brown-light);
}

.score-label {
  font-size: 0.9rem;
  color: var(--text-medium);
  margin-top: 4px;
  display: block;
}

.score-rating {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.rating-badge {
  display: inline-block;
  padding: 6px 14px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 0.9rem;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.score-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.distance-metric {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--bg-medium);
  border-radius: 4px;
}

.metric-label {
  font-size: 0.9rem;
  color: var(--text-medium);
}

.metric-value {
  font-size: 1rem;
  font-weight: 600;
}

.score-bar-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.score-bar-bg {
  width: 100%;
  height: 8px;
  background-color: var(--bg-medium);
  border-radius: 4px;
  overflow: hidden;
}

.score-bar-fill {
  height: 100%;
  border-radius: 4px;
  transition: width 1s ease-out;
}

.score-bar-markers {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: var(--text-dark);
}

@media (max-width: 768px) {
  .score-card {
    font-size: 0.95rem;
  }

  .score-number {
    font-size: 2.5rem;
  }

  .score-header {
    padding: 10px;
  }

  .score-content {
    padding: 12px;
  }

  .score-details {
    margin-bottom: 10px;
  }
}

@media (max-width: 900px) {
  /* Side by side layout for mobile view */
  .score-card .score-content {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    gap: 0;
  }

  /* Create a top row container for better alignment */
  .score-card .score-points {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    margin-right: 50px; /* Fixed space between elements */
  }

  .score-card .score-rating {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-left: 50px; /* Fixed space between elements */
  }

  /* Make score number and badge visually balanced */
  .score-card .score-number {
    line-height: 1;
    margin-bottom: 2px;
  }

  .score-card .rating-badge {
    align-self: center;
    margin-top: 3px;
  }

  .score-card .score-label {
    margin-top: 0;
  }

  .score-card .score-details {
    flex: 0 0 100%;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid var(--border-dark);
  }
}

@media (max-width: 480px) {
  .score-card {
    border-radius: 4px;
    font-size: 0.9rem;
  }

  /* Adjust spacing for very small screens */
  .score-card .score-content {
    padding: 12px;
    justify-content: center;
    gap: 0;
    align-items: center;
  }

  /* Adjust spacing for smaller screens */
  .score-card .score-points {
    margin-right: 30px; /* Less space on small screens */
    padding: 4px 0;
  }

  .score-card .score-rating {
    margin-left: 30px; /* Less space on small screens */
    padding: 4px 0;
  }

  /* Fix alignment */
  .score-card .score-points {
    text-align: left;
  }

  .score-card .score-number {
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 0;
  }

  .score-card .score-label {
    font-size: 0.8rem;
  }

  .score-card .score-rating {
    margin-bottom: 0;
  }

  .score-card .rating-badge {
    margin-top: 0;
  }

  .score-card .score-details {
    margin-top: 8px;
    padding-top: 8px;
    gap: 8px;
  }

  .score-header {
    padding: 8px 12px;
  }

  .score-header h3 {
    font-size: 1rem;
  }

  .distance-metric {
    padding: 8px;
  }

  .metric-label {
    font-size: 0.85rem;
  }

  .metric-value {
    font-size: 0.95rem;
  }

  .rating-badge {
    padding: 4px 10px;
    font-size: 0.8rem;
  }
}
