.tarkov-map-container {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tarkov-map {
  width: 100%;
  height: 100%;
  z-index: 1;
  flex: 1;
  background-color: #2c2c2c;
}

/* Make sure the marker icon is properly styled */
.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block !important;
}

/* Styling for the dropper marker */
.guess-marker {
  filter: hue-rotate(
    140deg
  ); /* Make it distinct from the actual location marker */
}

.location-marker {
  /* Custom styles for the actual location marker */
}

/* Add leaflet coordinates control styling */
.leaflet-control-coordinates {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 14px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}

.leaflet-control-coordinates label {
  margin: 0 5px;
}

/* Ensure markers display correctly */
.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-shadow-pane {
  z-index: 500;
}

/* Add mobile optimizations for map controls */
@media (max-width: 768px) {
  /* Make zoom controls bigger and more spaced out for touch */
  .leaflet-control-zoom a {
    width: 36px !important;
    height: 36px !important;
    line-height: 36px !important;
    font-size: 18px !important;
  }

  /* Increase distance between control buttons */
  .leaflet-control-zoom-in {
    margin-bottom: 4px !important;
  }

  /* Position the zoom control at a more reachable location */
  .leaflet-control-zoom {
    margin: 12px !important;
  }

  /* Make attribution readable but less obtrusive */
  .leaflet-control-attribution {
    font-size: 9px !important;
    padding: 3px 6px !important;
  }
}
