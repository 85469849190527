.map-selector-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.map-selector-title {
    color: var(--color-gold-one);
    text-align: center;
    margin-bottom: 2rem;
}

.map-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
}

.map-item {
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s ease;
}

.map-item:hover {
    transform: translateY(-5px);
    text-decoration: none;
}

.map-card {
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid var(--color-gold-two);
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.map-thumbnail {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-bottom: 1px solid rgba(154, 136, 102, 0.3);
}

.map-name {
    padding: 1rem;
    text-align: center;
    font-size: 1.1rem;
    color: var(--color-gold-one);
    font-weight: bold;
}

/* Add more styling for the map title on the map page */
.map-title {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 1.2rem;
    z-index: 1000;
    pointer-events: none;
}
