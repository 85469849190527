.uploader-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--bg-medium);
  border-radius: 8px;
  box-shadow: 0 2px 10px var(--shadow-dark);
}

.uploader-container h2 {
  margin-top: 0;
  color: var(--text-light);
  border-bottom: 1px solid var(--border-light);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: var(--text-light);
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  font-size: 16px;
  background-color: var(--bg-dark);
  color: var(--text-light);
}

.help-text {
  margin-top: 5px;
  font-size: 14px;
  color: var(--text-medium);
}

.error-message {
  background-color: rgba(231, 76, 60, 0.2);
  color: #ff6b6b;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  border-left: 4px solid #e74c3c;
}

.form-actions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.selected-files-info {
  margin-left: 15px;
  color: var(--text-medium);
}

.btn {
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-primary {
  background-color: var(--accent-red);
  color: var(--text-light);
}

.btn-primary:hover:not(:disabled) {
  background-color: var(--accent-red-bright);
}

.btn:disabled {
  background-color: var(--bg-light);
  color: var(--text-dark);
  cursor: not-allowed;
}

.upload-results {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--border-light);
}

.upload-results h3 {
  margin-top: 0;
  color: var(--text-light);
}

.success-result {
  background-color: rgba(46, 204, 113, 0.2);
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  border-left: 4px solid #2ecc71;
  color: var(--text-medium);
}

.success-result a {
  color: var(--accent-brown-light);
  text-decoration: none;
}

.success-result a:hover {
  text-decoration: underline;
}

.failure-result {
  background-color: rgba(231, 76, 60, 0.2);
  padding: 10px;
  border-radius: 4px;
  border-left: 4px solid #e74c3c;
  color: #ff6b6b;
}

.failure-result ul {
  margin-top: 5px;
  padding-left: 20px;
}

.failure-result li {
  margin-bottom: 5px;
}

/* Optimization Options Styling */
.optimization-options {
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: var(--bg-light);
  border-radius: 0.25rem;
}

.optimization-options h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: var(--text-light);
}

.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.form-check-input {
  margin-right: 0.5rem;
}

.form-check-label {
  color: var(--text-medium);
}

.optimization-controls {
  margin-top: 1rem;
  padding-left: 1.5rem;
  border-left: 2px solid var(--border-light);
}

.optimization-controls .form-group {
  margin-bottom: 1rem;
}

.optimization-controls small {
  display: block;
  margin-top: 0.25rem;
  color: var(--text-medium);
}

.form-control-range {
  width: 100%;
  max-width: 300px;
  background-color: var(--bg-dark);
}

/* Preview Styling */
.optimization-preview {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-light);
}

.optimization-preview h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: var(--text-light);
}

.optimizing-message {
  font-style: italic;
  color: var(--text-medium);
}

.optimization-summary {
  margin-bottom: 1rem;
  padding: 0.75rem;
  background-color: var(--bg-dark);
  border-radius: 0.25rem;
  color: var(--text-medium);
}

.optimization-summary p {
  margin: 0.25rem 0;
}

.optimization-summary strong {
  color: var(--accent-brown-light);
}

.file-previews {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.file-preview-item {
  display: flex;
  padding: 0.75rem;
  border: 1px solid var(--border-light);
  border-radius: 0.25rem;
  background-color: var(--bg-dark);
}

.file-info {
  flex: 1;
  color: var(--text-medium);
}

.file-info strong {
  display: block;
  margin-bottom: 0.25rem;
  word-break: break-all;
  color: var(--text-light);
}
